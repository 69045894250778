body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.test-class {
  height: 50px;
  min-width: fit-content;
}

.pac-container {
  z-index: 10000000
}

.hidden {
  display: none;
}

.error-message {
  text-align: center;
  color: rgb(152, 1, 1);
}

.forgot-password-btn {
  padding: 2px;
  margin: 5px 0 0 0;
  cursor: pointer;
}

.send-pswd-email-btn {
  max-width: 100px!important;
}

.catch-log-btn {
  position: absolute;
  z-index: 1000;
  top: 25px;
  left: 260px;
  background-color: rgba(3, 3, 42, 0.75);
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.catch-log-infowindow table th {
  text-align: left;
}

.polygon-id-banner {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(3, 3, 42, 0.75);
  z-index: 100000;
  padding: 0 10px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  color: white;
}

.polygon-id-banner-search {
  position: absolute;
  top: 80px;
  right: 80px;
  background-color: rgba(3, 3, 42, 0.75);
  z-index: 100000;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  color: white;
  max-height: 70vh;
  overflow-y: scroll; /* Add this line to always show the vertical scroll bar */
  padding: 10px 20px;
}

.sub-area-search-btn {
  position: absolute;
  z-index: 1000;
  top: 25px;
  left: 125px;
  background-color: rgba(3, 3, 42, 0.75);
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.sub-area-search-btn:hover {
  background-color: rgba(3, 3, 42, 0.952);
}

.sub-area-search-content {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(3, 3, 42, 0.75);
  z-index: 100000;
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  color: white;
  width: fit-content;
  // align items horizontally
  display: flex;
  justify-content: space-around;

  p {
    margin: 5px;
  }
}

.signUp-login-btn {
  border: 1px solid white;
  border-radius: 50px;
  position: absolute;
  width: 95px;
  z-index: 100000;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 7px 15px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.143);
}

.signUp-login-btn:hover {
  cursor: pointer;
  transform: scale(1.03); /* Apply scaling on hover */
  transition-delay: .1s; /* Add a delay of 1 second */
}

.signUp-homepage-btn {
  background-color:rgba(92, 180, 92, 0.812);
  top: 10px;
  right: 200px;
}

.signUp-homepage-btn:hover {
  background-color:rgba(92, 180, 92, 0.961);
}

.login-homepage-btn {
  background-color:rgba(44, 51, 64, 0.353);
  top: 10px;
  right: 80px;
}

.login-homepage-btn:hover {
  background-color:rgba(44, 51, 64, 0.791);
}

.sub-area-search {
  font-weight: 600;

  select {
    margin: 2px 0px 10px 0px;
    padding: 2px;
    width: 175px;
    text-align: center;
  }

}

.user-settings-bar {
  position: absolute;
  top: 15px;
  width: 90%;
  max-width: 500px;
  z-index: 100000000;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
}

.banner-message {
  position: absolute;
  top: 60px;
  width: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(185, 185, 199, 0.75);
  z-index: 100000;
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.written-logo {
  position: absolute;
  font-family: roboto;
  font-style: italic;
  top: 10px;
  left: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;

  img {
    margin: 0 10px;
  }
}

.modal-general {
  position: relative;
  height: fit-content;
  max-height: 75%;
  width: fit-content;
  min-width: 300px;
  max-width: 90vw;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  z-index: 1000000000;
  display: flex; /* Use flexbox to center content */
  flex-direction: column; /* Arrange inputs vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  padding: 20px 25px;
  border-radius: 10px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.787);
  overflow: scroll;

  .medium-input {
  
    padding: 10px;

  input {
    padding: 5px;
    width: 80px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid black;
    float: right;
    margin-left: 10px;
    margin-right: 80px;
  }

  input[type="datetime-local"] {
    width: 200px;  
    margin-right: -5px;  
  }
  
}

  .large-input input {
    display: flex; /* Use flexbox to center content */
    flex-direction: column; /* Arrange inputs vertically */
    margin: 15px;
    padding: 10px;
    width: 250px;
    border-radius: 15px;
    font-size: 16px;
    border: 1px solid black;
  }

textArea {
  display: flex; /* Use flexbox to center content */
  flex-direction: column; /* Arrange inputs vertically */
  margin: 15px;
  padding: 10px;
  width: 300px;
  height: 150px;
  border-radius: 15px;
  font-size: 16px;
  border: 1px solid black;
}

  .tide-data-container {

    label {
      margin: -3px;
    }

  }

  .tide-location-selection {
    display: flex;
    margin: 10px;

    input {
      width: 225px;
      height: 30px;
      margin-left: 5px;
    }
  }

  .tide-date-selection {
    display: flex;
    margin: 10px;

    input {
      width: 130px;
      height: 25px;
      margin: 0 10px 0 0px;
    }
  }

  .sign-up-btns {
    // run vertically
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

}

.close-btn {
  position: absolute;
  top: 3px;
  right: 10px;
  color: black;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  background-color: white;
  border: none;
}

.close-btn {
  cursor: pointer;
}

.green-btn {
  width: 100%;
  padding: 15px 0px;
  background-color: rgb(46, 227, 137);
  border-radius: 50px;
  border: none;
  margin-top: 15px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.212);
  cursor: pointer;
}

.grey-btn {
  width: 100%;
  padding: 10px;
  background-color: rgba(11, 25, 177, 0.884);
  border-radius: 50px;
  border: none;
  margin-top: 15px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.212);
  cursor: pointer;
}

.small-green-btn {
  width: 150px;
}

.green:hover {
  background-color: rgb(40, 204, 122);
  cursor: pointer;
}

.layer-hide {
  position: absolute;
  z-index: 1000;
  top: 60px;
  right: 10px;
  background-color: rgba(3, 3, 42, 0.677);
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  color: white;
  cursor: pointer;
}

.webcam-window {
  position: absolute;
  z-index: 100000000;
  width: 90vw;
  height: 95vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.webcam-window-close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  color: white;
  cursor: pointer;
  background-color: rgba(3, 3, 42, 0.75);
  text-align: center;
  padding: 2px 12px;
  border-radius: 50%;

  p {
    margin: 0;
    padding: 0;
    font-size: 22px;
    margin-bottom: 5px;
  }
}

.webcam-window-close-btn:hover {
  background-color: rgba(14, 14, 139, 0.907);
}

.checkbox-menu {
  position: absolute;
  top: 82px;
  right: 10px;
  font-size: 12px;
  background-color: rgba(29, 29, 53, 0.952);
  z-index: 10000;
  text-align: right;
  border-radius: 10px;
  padding: 7px;
  color: white;
  display: flex; /* Use flex container */
  flex-direction: column; /* Set the direction to vertical */

  label {
    padding: 3px;
  }

  input[type='checkbox'] {
    margin-left: 10px;
  }

  .icon-description {
    position: absolute;
    z-index: 10000;
    right: 55px;
    background-color: rgba(255, 255, 255, 0.821);
    color: black;
    padding: 2px 5px;
    border-radius: 5px;
    text-align: center;
    white-space: nowrap;
    padding: 6px;
  }

}

.show-history-btn {
  color: blue;
  cursor: pointer;
  font-weight: 400;
}

.fishing-area-info-box-close {
  position: absolute;
  top: 0px;
  right: 7px;
  z-index: 1000;
  font-size: 18px;
  cursor: pointer;
}

.fishing-area-info-box {
  position: absolute;
  // bring to the very front
  z-index: 10000000000;
  width: 95%;
  height: 80%;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  text-align: center;

  h3 {
    font-size: 24px;
    text-align: center;
    margin: 0;
    padding: 5px;
    margin-bottom: -10px;
  }
}

.search-container {
  position: relative;
  float: right;
  margin: 10px;


  input {
    font-size: 18px;
  }
}

.fishing-area-info-box table {
  display: block;
  max-height: 90%; /* Set the maximum height */
  overflow-y: auto; /* Enable vertical scroll if content exceeds max height */
  border-collapse: collapse;
  width: 100%; /* Set the table to 100% width */
  text-align: center;
  border-radius: 10px;
  font-size: 16px;

  th {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 5px;
  }

  input {
    border-radius: none;
    margin: 5px;
    border: none;
    border-bottom: .5px solid black;
    font-size: 16px;
  }

  .small-input input {
    width: 110px;
  }

  tbody tr:hover {
    background-color: #565a7a3b;
  }
  
}

.fishing-area-info-box table tr:nth-child(odd) {
  background-color: #f2f2f2; /* Light gray for odd rows */
}

.fishing-area-info-box table tr:nth-child(even) {
  background-color: #ffffff; /* White for even rows */
}

.history-page {
  position: absolute;
  background-color: rgba(3, 3, 42, 0.819);
  z-index: 10000;
  width: 95vw;
  max-width: 450px;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  color: white;

  .history-close-btn {
    position: absolute;
    right: 10px;
    color: white;
    cursor: pointer;
    font-size: 24px;
  }

  h3 {
    text-align: center;
  }

  table {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;

    // tr:nth-child(even) {
    //   background-color: rgba(3, 3, 42, 0.287);
    // }

    th {
      text-align: center;
      font-weight: 400;
    }

    td {
      position: relative;
      font-weight: 300;
      padding: 10px 0px;
      left: 20px;
    }

  }
}

.graph-close-btn {
  background-color: rgba(3, 3, 42, 0.75);
}

.history-graph {
  position: absolute;
  top: 55px;
  right: 75px;
  width: 400px;
  height: 285px;
  background-color: rgba(3, 3, 42, 0.75);
  border-radius: 10px;
  z-index: 10000;

  h3 {
    position: relative;
    text-align: left;
    color: white;
    border-bottom: 1px solid white;
    width: 85%;
    left: 2%;
  }

  .close-btn {
    position: absolute;
    top: 3px;
    right: 10px;
    color: white;
    cursor: pointer;
    font-size: 24px;
    font-weight: 600;
  }
}


.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);  
  z-index: 10000000000;
}

.colour-legend {
  position: absolute;
  left: 50%;
  top: 95%;
  transform: translate(-50%, -50%);  
  z-index: 100;
  width: 50%;
  height: 20px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
  display: flex;
  color: white;
  text-align: center;
  margin: none;
  padding: none;

  .colour-calm {
    width: 20%;
    // background-color: rgb(122,85,121);
    background-image: linear-gradient(to right, rgb(122,85,121) 80% , rgb(54,45,192));
    position: relative;
    border-radius: 10px 0 0 10px;
  }

  .colour-1-2 {
    width: 20%;
    // background-color: rgb(54,45,192);
    background-image: linear-gradient(to right, rgb(54,45,192) 80% , rgb(72,120,65));
    position: relative;
  }

  .colour-2-3 {
    width: 20%;
    // background-color: rgb(72,120,65);
    background-image: linear-gradient(to right, rgb(72,120,65) 80% , rgb(188,139,74));
    position: relative;
  }

  .colour-3-4 {
    width: 20%;
    // background-color: rgb(188,139,74);
    background-image: linear-gradient(to right, rgb(188,139,74) 80% , rgb(187, 93, 90));
    position: relative;
  }

  .colour-4-plus {
    width: 20%;
    background-color: rgb(187, 93, 90);
    position: relative;
    border-radius: 0 10px 10px 0;
  }

}

.wind-table {
  position: absolute;
  top: 90px;
  left: 40px;
  font-size: 12px;
  background-color: rgba(29, 29, 53, 0.952);
  padding: 5px;
  border-radius: 10px;
  color: white;
  height: 260px;
  overflow: scroll;

  table {
    border-collapse: collapse;

    td:nth-child(2) {
      width: 75px;
      text-align: center;
    }
    
    td:nth-child(3) {
      text-align: center;
    }
  
    tr:nth-child(even) {
      background-color: rgba(3, 3, 42, 0.287);
    }

    th {
      font-size: 14px;
      font-weight: 800;
      border-bottom: .5px solid white;
      padding-bottom: 5px;
    }

    tr {
      height: 20px;
    }

    tbody tr:hover {
      background-color: rgba(53, 155, 166, 0.477);
      color: white;
      cursor: pointer;
    }

  }
  
}

.toggle-checkbox {
  display: none;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border 0.2s;
  border: 2px solid transparent; /* Add a border around the checkboxes */
  margin: 2px;
  border-radius: 5px;
}

.checkbox-label.selected {
  border: 2px solid rgba(122, 122, 177, 0.781); /* Adjust the border styles as needed */
}

.icon-description {
  margin-left: 5px; /* Add some space between the icon and the text */
}

.wind-table-hide {
  position: absolute;
  z-index: 1000;
  top: 68px;
  left: 30px;
  background-color: rgba(3, 3, 42, 0.677);
  padding: 2px 15px;
  border-radius: 15px;
  color: white;
  font-weight: 400;
  cursor: pointer;
}

// MOBILE CHANGES

@media only screen and (max-width: 1100px) {

  .clear-area-btn-high {
    position: absolute;
    z-index: 1000;
    top: 110px;
    left: 5px;
    background-color: rgba(3, 3, 42, 0.75);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .signUp-login-btn {
    border: 1px solid white;
    border-radius: 50px;
    position: absolute;
    width: 75px;
    z-index: 1000;
    color: white;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.143);
  }

  .clear-area-btn {
    position: absolute;
    z-index: 1000;
    top: 250px;
    left: 5px;
    background-color: rgba(3, 3, 42, 0.75);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  
  .signUp-homepage-btn {
    background-color:rgba(92, 180, 92, 0.812);
    top: 20px;
    right: 85px;
  }
  
  .login-homepage-btn {
    background-color:rgba(44, 51, 64, 0.353);
    top: 20px;
    right: 5px;
  }

  .user-settings-bar {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding: 0;
    margin: -5px;
    font-size: 10px;
  }

}

@media only screen and (max-width: 700px) {

  .modal-general {
    position: relative;
    height: fit-content;
    max-height: 85%;
    width: fit-content;
    min-width: 300px;
    max-width: 90vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    z-index: 1000000000;
    display: flex; /* Use flexbox to center content */
    flex-direction: column; /* Arrange inputs vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    padding: 20px 25px;
    border-radius: 10px;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.787);
    overflow: scroll;
  }

  .catch-log-btn {
    position: absolute;
    z-index: 1000;
    top: 20px;
    left: 80px;
    background-color: rgba(3, 3, 42, 0.75);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  .sub-area-search-btn {
    position: absolute;
    z-index: 1000;
    top: 40px;
    left: 65px;
    background-color: rgba(3, 3, 42, 0.75);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  .clear-area-btn {
    position: absolute;
    z-index: 1000;
    top: 250px;
    left: 5px;
    background-color: rgba(3, 3, 42, 0.75);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  .clear-area-btn-high {
    position: absolute;
    z-index: 1000;
    top: 110px;
    left: 5px;
    background-color: rgba(3, 3, 42, 0.75);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  .clear-area-btn-low {
    position: absolute;
    z-index: 1000;
    top: 250px;
    left: 5px;
    background-color: rgba(3, 3, 42, 0.75);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  .sub-area-search-content {
    top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    max-height: 45px;
    overflow: scroll;
  }

  .fishing-area-info-box {
    position: absolute;
    // bring to the very front
    z-index: 10000000000;
    width: 98%;
    height: 80%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  
    h3 {
      font-size: 18px;
      text-align: center;
      margin: 0;
      padding: 5px;
      margin-bottom: 0px;
    }
  }
  
  .search-container {
    position: relative;
    float: right;
    margin: 10px;
  
    input {
      font-size: 14px;
    }
  }
  
  .fishing-area-info-box table {
    display: block;
    max-height: 90%; /* Set the maximum height */
    overflow-y: auto; /* Enable vertical scroll if content exceeds max height */
    border-collapse: collapse;
    width: 100%; /* Set the table to 100% width */
    text-align: center;
    border-radius: 10px;
    font-size: 13px;
  
    th {
      font-size: 14px;
      font-weight: 700;
      padding-bottom: 5px;
    }
  
    input {
      margin: 5px;
      border: none;
      border-bottom: .5px solid black;
      font-size: 13px;
    }
  
    .small-input input {
      width: 105px;
    }
  
    tbody tr:hover {
      background-color: #565a7a3b;
    }
    
  }

  .webcam-window {
    position: absolute;
    z-index: 100000000;
    width: 90vw;
    height: 90vh;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .checkbox-menu {
    position: absolute; 
    top: 78px;
    right: 5px; 
    font-size: 13px;
    display: block;
    width: fit-content;
    background-color: rgba(29, 29, 53, 0.952);
    z-index: 10000;
    border-radius: 10px;
    padding: 5px 1px;
    color: white;
  
    label {
      position: relative;
      display: block;
      text-align: left;
      left: 0px;

      margin-bottom: 5px; /* Adds some space between each label and checkbox */
    }

    input[type='checkbox'] {
      float: right;
    }

  }

  .wind-table {
    position: absolute;
    top: 80px;
    left: 5px;
    font-size: 10px;
    background-color: rgba(3, 3, 42, 0.677);
    padding: 2px;
    border-radius: 10px;
    color: white;
    height: 160px;
    overflow: scroll;

    table {
      border-collapse: collapse;
  
      td:nth-child(2) {
        width: 55px;
        text-align: center;
      }
      
      td:nth-child(3) {
        text-align: center;
      }
    
      tr:nth-child(even) {
        background-color: rgba(3, 3, 42, 0.287);
      }
  
      th {
        font-size: 14px;
        font-weight: 800;
        border-bottom: .5px solid white;
        padding-bottom: 5px;
      }
  
      tr {
        height: 20px;
      }
  
      tbody tr:hover {
        background-color: rgba(53, 155, 166, 0.477);
        color: white;
        cursor: pointer;
      }
  
    }
    
  }

  .wind-table-hide {
    position: absolute;
    z-index: 1;
    top: 80px;
    left: 5px;
    background-color: rgba(3, 3, 42, 0.677);
    padding: 1px 12px;
    border-radius: 15px;
    color: white;
    cursor: pointer;
  }

  .layer-hide {
    position: absolute;
    z-index: 100;
    top: 60px;
    right: 5px;
    background-color: rgba(3, 3, 42, 0.677);
    padding: 1px 12px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 400;
    color: white;
    cursor: pointer;
  }

  .colour-legend {
    width: 90%;
    top: 83%;
  }

}

.show-link-button {
  position: absolute;
  bottom: 0px;
  left: 15px;
}

// FISHING AREA WINDOW

.fishing-area-information-window {
  position: absolute;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.923);
  min-width: 350px;
  max-width: 800px;
  width: 75%;
  height: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: scroll;

.species-component-container {
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;

  .expand-button {
    width: 100%;
  }
}

}

// Sidebar

.sidebar-menu-container {
  display: flex; /* Use flex container */
  flex-direction: column; /* Set the direction to vertical */
  margin-top: -25px;
  z-index: 100000000;

  span {
    cursor: pointer;
  }

  h1 {
    font-weight: 300;
    font-size: 36px;
    color: rgb(102, 96, 96);
  }

  .normal-link {
    font-size: 24px;
  }

  .small-link {
    font-size: 12px;
  }

  .sidebar-menu-links {
    margin-top: 75px;
  }

}

.menu-item {
  display: block;
  text-decoration: none;
  color: rgb(27, 25, 25);
  margin-top: 20px;
  transition: color 0.3s, transform 0.3s; /* Add transitions for color and transform */
}

.menu-item:hover {
  color: rgb(110, 104, 104);
  transform: scale(1.05); /* Apply scaling on hover */
  transition-delay: .1s; /* Add a delay of 1 second */
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 20px;
  z-index: 100000;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(27, 26, 26, 0.701);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(255,255,255);
  background: linear-gradient(124deg, rgba(255,255,255,1) 0%, rgba(243,241,245,1) 50%, rgba(221,220,232,1) 100%);  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: 100vh;
  overflow-y: auto; /* Use auto to add a scroll only when needed.. not always working */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  margin-top: 50px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

// Sign up modal

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.643);
  z-index: 1000000;
}

// settings page

.settings-modal {

  h5 {
    font-weight: 800;
  }

}

.settings-container {
  height: 100%;
  min-height: 600px;

  h2 {
    text-align: center;
    margin-top: -10px;
  }

  h3 {
    margin-left: -8px;
  }

  .settings-p {
    font-size: 12px;
    text-align: center;
    margin-top: -10px;
    padding: 0;
  }
}

.radio-group {
  display: flex; /* Display the labels in a row */
  gap: 10px; /* Adjust the gap between radio buttons */
}

label {
  display: flex; /* Display the label and input in a row */
  align-items: center; /* Vertically center the content */
}

input[type="radio"] {
  margin: 5px; /* Adjust the spacing between the radio button and label text */
  width: auto;
}

.settings-close-btn {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 25px;
  cursor: pointer;
}

// feedback modal

.feedback-modal {

  .feedback-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

// Fishing reports

.fishing-reports-container {

  a {
    text-decoration: none;
    color: rgb(35, 35, 117);
  }
}

.tide-table-container table {
  border-collapse: collapse; /* This prevents double borders between cells */
}

.tide-table-container table, .tide-table-container th, .tide-table-container td {
  border: 1px solid black; /* Add a border to the table, th, and td elements */
  padding: 8px; /* Add some padding for better spacing */
  width: 100%;
  // spread elements evenly
  table-layout: fixed;
  text-align: center;

}

.catch-log-user-location {
  display: flex;
  flex-direction: row;
}

.catch-log-map-btn {
  margin: 5px;
  border: 1px solid blue;
  padding: 3px;
  border-radius: 5px;
  background-color: white;
  color: blue;
  cursor: pointer;
}

.catch-log-map-btn-selected {
  background-color: rgba(11, 25, 177, 0.884);
  color: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.763);
}

.catch-log-container {
  position: relative;
  min-width: 350px;
  margin-top: 20px;
  height: 100vh;

  select {
    width: 200px;
    margin-top: 5px;
    display: flex; /* Use flexbox to center content */
    flex-direction: column; /* Arrange inputs vertically */
    padding: 5px;
    width: 200px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid black;
  }

  .catch-log-radio-btn {
    display: flex;
    flex-direction: row;
    margin: 10px;

    p {
      margin: 0;
      font-size: 12px;
    }

  }

}

.new-site-message {
  position: fixed; /* Stay in place */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
  z-index: 999999;
  min-width: 300px;
  min-height: 190px;
  width: 30%;
  height: 20%;
  background-color: white;
  text-align: center;
  border-radius: 10px;
}

.visit-site-btn {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, -50%);  
  width: 200px;
  padding: 20px;
  background-color: rgb(0, 115, 255);
  border-radius: 10px;
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.visit-site-btn:hover {
  cursor: pointer;
  background-color: rgb(0, 105, 234);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.509);
}

.new-site-close-btn {
  position: absolute;
  top: 4px;
  right: 10px
}

.new-site-close-btn:hover {
  cursor: pointer;
  color: rgb(37, 37, 73)
}

a {
  text-decoration: none;
}